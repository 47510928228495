<template>
  <v-container class="container-card">
    <FiltroRelatorios @selectedFilters="filters = $event" />
    <Relatorios :filters="filters" :list="menus" />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import FiltroRelatorios from "@/components/comissao/relatorios/FiltroRelatorios";
import Relatorios from "@/components/comissao/relatorios/Relatorios";

export default {
  components: {
    FiltroRelatorios,
    Relatorios
  },
  data() {
    return {
      filters: {},
      menus: [
        {
          title: "Relatório Geral",
          icon: "mdi-file-outline",
          reportList: [
            {
              text: "Relatório Geral",
              service: "comissaoGeralUnico",
              permission: 1009
            },
            {
              text: "Relatório Horas Extras",
              service: "horaExtra",
              permission: 1161
            }
          ]
        },
        {
          title: "Comissão EC",
          icon: "mdi-file-outline",
          reportList: [
            {
              text: "Relatório Comissão EC",
              service: "comissaoGeralEscritorio",
              permission: 986
            }
          ]
        },
        {
          title: "Relatório Mapa",
          icon: "mdi-file-outline",
          reportList: [
            {
              text: "Relatório Mapa Geral",
              service: "mapaResumoGeral",
              permission: 1021
            },
            {
              text: "Relatório Mapa Empresa",
              service: "mapaResumoEmpresa",
              permission: 1022
            }
          ]
        },
        {
          title: "Relatório Notas Fiscais",
          icon: "mdi-file-outline",
          reportList: [
            {
              text: "Relatório Notas Fiscais",
              service: "notaFiscal",
              permission: 1027
            },
            {
              text: "Relatório Notas Fiscais Vendas",
              service: "notaFiscalVenda",
              permission: 984
            }
          ]
        },
        {
          title: "Relatório Notas Fiscais Seguros",
          icon: "mdi-file-outline",
          reportList: [
            {
              text: "Relatório Notas Fiscais Seguros",
              service: "notaFiscalSeguro",
              permission: 983
            }
          ]
        }
      ]
    };
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Relatórios Comissão");
  }
};
</script>

<style></style>
